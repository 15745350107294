export default class FileHelper {
  static encodeImage (file) {
    return new Promise(resolve => {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        resolve(reader.result);
      });

      reader.readAsDataURL(file);
    });
  }

  static encodeVideo (file) {
    return URL.createObjectURL(file);
  }
}
