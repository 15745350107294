<script>
export default {
  name: 'RatioContainer',
  functional: true,
  props: {
    ratio: {
      type: Number,
      default: 46.73,
    },
  },
};
</script>

<template lang="pug" functional>
div.ratio-container(:style="{paddingTop: `${props.ratio}%`}" :class="data.staticClass")
  div
    slot

</template>

<style lang="postcss" scoped>
.ratio-container {
  width: 100%;
  position: relative;
  & > div {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;

    & >>> img,
    & >>> video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
