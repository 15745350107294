<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import UIIcon from './UIIcon';
import UIFormItem from './UIFormItem';
import UIButton from './UIButton';
import RatioContainer from '../utils/RatioContainer';
import { ValidationProvider } from 'vee-validate';

import Loading from '../Loading';
const RatioContainerWrapper = {
  name: 'RatioContainerWrapper',
  functional: true,
  render: (h, { props, children }) => {
    return props.ratio ? h(RatioContainer, { props: { ratio: props.ratio } }, children) : children;
  },
};
@Component({
  inheritAttrs: false,
  components: {
    UIIcon,
    UIFormItem,
    UIButton,
    RatioContainerWrapper,
    Loading,
    ValidationProvider,
  },
  props: {
    disabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    file: {

    },
    specs: {
      type: String,
    },
    ratio: {
      type: Number,
      // default: 50
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    fieldNameOverride: {
      type: String,
    },
    vid: {
      type: String,
      default: undefined,
    },
    ...UIFormItem.options.props,
  },
})
export default class UIFileUpload extends Vue {
  validationDisabled = false

  created () {
    // NOTE(Jack): This ensures that validation will be disabled when there has been nothing given to the input yet
    this.validationDisabled = !!this.$props.file;
  }

  async imageChange (e) {
    await this.syncValidationInfo(e);
    this.$emit('change', e.target.files);
  }

  async clearImage () {
    await this.syncValidationInfo(null);
    this.$emit('clear');
  }

  async syncValidationInfo (value) {
    this.validationDisabled = false;
    this.$refs.validationProvider.syncValue(value);
    const validationResult = await this.$refs.validationProvider.validate();
    this.$refs.validationProvider.applyResult(validationResult);
    return validationResult;
  }
}
</script>

<template>
  <ValidationProvider :disabled="validationDisabled" :detectInput="false" tag="div" :rules="rules" v-slot="{ errors }" :vid="vid" :name="!fieldNameOverride ? title : fieldNameOverride" ref="validationProvider">
    <UIFormItem :title="title" :instructions="instructions" :required="required" :root="true" :error="!!errors[0]" :error-msg="errors[0]">
      <RatioContainerWrapper :ratio="ratio">
        <label class="upload-button relative h-100 overflow-hidden" :class="{'-loading': loading, '-error': !!errors[0], '-disabled': disabled}">
          <div class="bgimg contain bg-center absolute absolute--fill" v-if="file && file.thumbnail" v-show="!loading" :style="{backgroundImage: `url('${file.thumbnail}')`}"></div>
          <Loading class="absolute absolute-center" v-if="loading"></Loading>
          <div :style="{ opacity: !file || !file.thumbnail ? 1 : 0 }" v-show="!loading">
            <slot>
              <UIIcon class="dib w2" name="upload"></UIIcon>
              <span class="db">{{ $t('Upload') }}</span>
            </slot>
            <input class="uploadinput dn" @change="imageChange" type="file" name="image-upload" :disabled="disabled" v-bind="$attrs" />
          </div>
        </label>
      </RatioContainerWrapper>

      <p class="flex items-center mt2" v-if="file">
        <span class="f-thead mr-auto w-70 truncate">{{ file.name }}</span>
        <UIButton class="dib fill-manatee" v-if="!disabled" @click="clearImage" tiny="tiny" light="light" icon="trash" />
      </p>
      <p class="tr f-thead mr-auto mt2" v-else-if="specs">{{ specs }}</p>
    </UIFormItem>
  </ValidationProvider>
</template>

<style lang="postcss" scoped>
@import "../../styles";

.upload-button {
  @apply --buttonGhostTemplate;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  &.-loading {
    pointer-events: none;
  }
  &.-error {
    border-color: var(--colorErrorFlat);
    box-shadow: none;
    border-width: 2px;
  }
  &.-disabled {
    cursor: not-allowed;
  }
}
.absolute-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.error {
  @apply --f7;
  color: var(--colorErrorFlat);
}
</style>
